<template>
  <div class="contact-form">
    <h1>Contact Us</h1>
    <form @submit.prevent="sendMail">
      <div>
        <label for="name">Name:</label>
        <input type="text" v-model="form.name" required />
      </div>
      <div>
        <label for="email">Email:</label>
        <input type="email" v-model="form.email" required />
      </div>
      <div>
        <label for="subject">Subject:</label>
        <input type="subject" v-model="form.subject" required />
      </div>
      <div>
        <label for="message">Message:</label>
        <textarea v-model="form.message" required></textarea>
      </div>
      <button type="submit">Send</button>
    </form>
    <p v-if="status">{{ status }}</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      status: "",
    };
  },
  name: "ContactForm",
  methods: {
    async sendMail() {
      try {
        const response = await axios.post(
          "http://127.0.0.1:8080/api/contact",
          this.form
        );
        this.status = response.data.status;
      } catch (error) {
        this.status = "Failed to send email.";
      }
    },
  },
};
</script>

<style>
/* Add some styling */
</style>
